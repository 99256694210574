import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Masonry } from '@mui/lab';
import moment from 'moment';
import Image from 'next/image';

import Checkers from '../../../../helpers/class/Checkers';
import ViewPort from '../../../ViewPort';
import {useEffect} from "react";
import Link from "next/link";

export default function MasonryType ( props ) {

  const builder = props.builder;
  const content = props.content;
  const articles = props.articles;
  const articlesLength = props.articlesLength;

  let container_class = 'container';
  if ( props.type == 'blog' && Checkers.isValidString( builder.builder_news_view_width ) ) {
    container_class = builder.builder_news_view_width;
  } else if ( props.type == 'category' && Checkers.isValidString( content.category_view_width ) ) {
    container_class = content.category_view_width;
  }

  let skeletonArticles = [];
  for ( let i=0; i<6; i++ ) {
    skeletonArticles.push(
      <Skeleton key={i.toString()} height={490} width={265} className="b-rad-md"/>
    );
  }

  const textColor = () => {
    if (props.textColor) {
      return "text-light-color"
    }

    return;
  }

  return (
    <div className={`${container_class} pb-5`}>
      <div className="row w-100 m-0 ">
        <div className={'col-12 p-0'}>
          <Masonry spacing={2} columns={{ xs: 1, sm: 2, lg: Checkers.isValidArray(articles) && articles.length < 4 ? articles.length : 4 }}>
            {props.loadingArticles ?
              skeletonArticles
              : Checkers.isValidArray(articles) && articles.map( ( article, index ) => {

                let date_class = 'opacity-60 mt-0 fs-13\ ';
                date_class += Checkers.isValidObjString( article, 'subtitle' ) ? '' : 'm-0\ ';
                date_class += Checkers.isValidObjString( article, 'subtitle' ) || Checkers.isValidString( article.image ) ? 'align-self-start' : 'align-self-end';

                let card_title_class = 'card-title d-flex flex-column\ ';
                card_title_class +=
                  Checkers.isValidObjString( article, 'subtitle' ) && Checkers.isValidString( article.image ) ?
                    'border-top m-0'
                    :Checkers.isValidObjString( article, 'subtitle' ) && !Checkers.isValidString( article.image ) ?
                      'border-bottom m-b-10'
                      : 'm-0'
                ;

                let title_class = '';
                title_class +=
                  Checkers.isValidObjString( article, 'subtitle' ) && !Checkers.isValidString( article.image ) ?
                    'm-0'
                    : Checkers.isValidObjString( article, 'subtitle' ) ?
                      'mb-0'
                      : 'm-0'

                const created =
                  Checkers.isValidObjString( article, 'subtitle' ) || Checkers.isValidString( article.image ) ?
                    moment( new Date( ( article.created ) * 1000 ) ).format( 'dddd Do MMMM' )
                    :
                    moment( new Date( ( article.created ) * 1000 ) ).format( 'MMM DD' )
                ;

                  if ( (props.hasOwnProperty('articlesLimit') && (index<props.articlesLimit || props.articlesLimit===null)) || !props.hasOwnProperty('articlesLimit') ) {
                    return (
                        <ViewPort
                            // parentClassName={col_class}
                            key={article.id.toString()}
                            skeletonItem={
                              <Skeleton height={Checkers.isValidString(article.image) ? 490 : 190} width={265}
                                        className="b-rad-md"/>
                            }
                        >
                          <Link href={props.updateHref( article )}>
                            <a key={article.id.toString()}
                               onClick={() => props.clickHandler( article )}
                               className={props.setClass('')}
                               style={{cursor: article.has_description == "1" ? 'pointer' : null}}
                            >
                              <div
                                  className={"card m-0 " + ' ' + (Checkers.isValidString(props.itemClassName) ? props.itemClassName : '')}
                                  style={{backgroundColor: props.backgroundColor != null ? props.backgroundColor : ""}}>
                                <div
                                    style={{height: Checkers.isValidString(article.image) ? 298 : 'auto'}}>
                                  {
                                      Checkers.isValidString(article.image) &&
                                      <Image
                                          // className="image"
                                          layout={'fill'}
                                          sizes={'75vw'}
                                          quality={'75'}
                                          src={article.image}
                                          alt={'Post Img'}
                                          placeholder={'blur'}
                                          blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                      />
                                  }
                                </div>

                                <div
                                    className={Checkers.isValidString(article.image) ? 'card-body d-flex flex-column-reverse' : 'card-body d-flex flex-column'}
                                >
                                  <div className={card_title_class}>
                                    <h4 className={textColor() + " " + title_class}>{article.title}</h4>
                                    {
                                        Checkers.isValidObjString(article, 'tags') && !Checkers.isValidString(article.image) && !Checkers.isValidObjString(article, 'subtitle') &&
                                        <div className={textColor() + " via"}>
                                          {article.tags}
                                        </div>
                                    }
                                    {
                                      Checkers.isValidDate(created) ?
                                          <>
                                            <h6 className={textColor() + " " + date_class}>
                                              {created.toString()}
                                            </h6>
                                          </>
                                          :
                                          <></>
                                    }
                                    {/*{*/}
                                    {/*  article.has_description == "1" ?*/}
                                    {/*      <>*/}
                                    {/*        <a className="btn-link text-link fs-16" href="#/">*/}
                                    {/*          Read more*/}
                                    {/*        </a>*/}
                                    {/*      </>*/}
                                    {/*      :*/}
                                    {/*      <>*/}
                                    {/*      </>*/}
                                    {/*}*/}
                                  </div>
                                  <div>
                                    {
                                        Checkers.isValidObjString(article, 'subtitle') &&
                                        <div>
                                          <p className={textColor() + " line-clamp-2 m-0"}>{article.subtitle}</p>
                                        </div>
                                    }
                                    {
                                        Checkers.isValidObjString(article, 'tags') && (Checkers.isValidString(article.image) || Checkers.isValidObjString(article, 'subtitle')) &&
                                        <div
                                            className={textColor() + " " + (Checkers.isValidString(article.image) && Checkers.isValidObjString(article, 'subtitle') ? 'via m-b-10' : 'via')}
                                        >
                                          {article.tags}
                                        </div>
                                    }
                                  </div>

                                </div>
                              </div>
                            </a>
                          </Link>
                        </ViewPort>
                    )
                  }
              })
            }
          </Masonry>
        </div>
      </div>
    </div>
  )
}
